<template>
  <div v-if="queryMark" :class="['refundOrderManage', showFooter ? 'refundOrderManage-pab' : '']">
    <van-form ref="form" input-align="right" error-message-align="right" class="formBox" @submit.native.prevent>
      <div class="process">
        <p>{{ $t('退单审批全流程节点：') }}</p>
        <p style="margin-bottom: 4px;" >{{ $t('流程发起') }}-{{ $t('城市经理审批') }}-{{ $t('大区经理审批') }}-{{ $t('SO审批') }}-{{ $t('财务审批') }}</p>
      </div>
      <p v-if="!orderDetail.applyRefund"  class="previewTips">{{ headerTips }}</p>
      <div class="top refundOrder-item common-block" @click="goOrderDetail">
        <div class="common-t">
          <div class="common-t-l">{{$t('订单信息')}}</div>
        </div>
        <div style="padding: 8px; background: #F4F8FE;">
          <OrderTitle :orderDetail="orderDetail"></OrderTitle>
          <VehicleInfo :showMore="true" :orderDetail="orderDetail"></VehicleInfo>
        </div>
      </div>
      <div class="bottom refundOrder-item common-block">
        <div class="common-t">
          <div class="common-t-l">{{$t('申请信息')}}</div>
        </div>
        <van-field :label="$t('申请人')">
          <template #input>
            <span v-if="orderDetail.refund?.applyUserName">{{ `${orderDetail.refund?.salesDealerName} | ${orderDetail.refund?.applyUserName}` }}</span>
            <span v-else-if="userInfo.type === 1">{{ `${userInfo.dealers[0].name} | ${userInfo.name}` }}</span>
          </template>
        </van-field>
        <van-field
          v-model="params.applyReason"
          required
          show-word-limit
          :label="$t('申请原因')"
          :placeholder="$t('请输入申请原因')"
          :rules="[{ required: true }]"
          maxlength="500"
          :readonly="!showEdit"
          rows="4"
          autosize
          type="textarea"
        />
        <van-field :label="$t('申请材料')" required readonly></van-field>
        <div style="color: #646566; margin:8px 0 0 16px;" >{{ fileTips }}</div>
        <div :class="['uploadBox', !showEdit ? 'uploadBox_notEdit' : '']">
          <div class="imgBox">
            <van-uploader
              :fileList="imgList"
              accept="image/*"
              :deletable="showEdit"
              :preview-options="{ closeable: true }"
              :before-read="(file) => beforeRead(file, 'img')"
              :after-read="(file) => afterRead(file, 'img')"
              :before-delete="(file) => beforeDelete(file, 'img')"
              :upload-text="$t('上传图片')"
              :max-count="20"/>
          </div>
          <div class="fileBox">
            <van-uploader
              :fileList="fileList"
              upload-icon="notes"
              :deletable="showEdit"
              accept=".doc,.docx,.xlsx,,xlx,.pdf,.zip,.rar"
              :preview-options="{ closeable: true }"
              :before-read="(file) => beforeRead(file, 'file')"
              :after-read="(file) => afterRead(file, 'file')"
              :before-delete="(file) => beforeDelete(file, 'file')"
              :max-count="10"
              :upload-text="$t('上传附件')"
              @click-preview="downFile"
              />
          </div>
          <div class="tips">{{ $t('图片至多20张，单张不超过20M，文件至多10个，单个不超过30M') }}</div>
        </div>
      </div>
      <div v-if="showExamine" class="bottom refundOrder-item common-block">
        <div class="common-t">
          <div class="common-t-l">{{$t('审批备注')}}</div>
        </div>
        <van-field
          id="refuseReasonBox"
          ref="refuseReasonBox"
          v-model="rejectParams.refuseReason"
          required
          show-word-limit
          :label="$t('审批备注')"
          :placeholder="$t('请输入审批备注，驳回时必填')"
          :rules="[{ required: true }]"
          maxlength="200"
          rows="4"
          autosize
          type="textarea"
        />
      </div>
      <div v-if="tripDetails && tripDetails.trips.length > 0" class="trip refundOrder-item common-block">
        <div class="common-t">
          <div class="common-t-l">{{$t('轨迹')}}</div>
        </div>
        <Trips :details="tripDetails"></Trips>
      </div>
      <div v-if="showFooter" class="common-footer">
        <template v-if="showRefund">
          <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('取消申请')}}</van-button>
          <van-button class="btn submit" type="submit" @click="submitExamine">{{$t('提交审核')}}</van-button>
        </template>
        <template v-if="isSelf">
          <template v-if="judgeReject">
            <van-button class="btn back" native-type="button" @click.stop="revokeApply(0)">{{$t('取消申请')}}</van-button>
            <van-button class="btn submit" type="submit" @click="submitExamine">{{$t('重新提交审核')}}</van-button>
          </template>
          <template v-else>
            <van-button class="btn back" native-type="button" @click.stop="revokeApply(1)">{{$t('撤回')}}</van-button>
            <van-button class="btn submit" type="submit" @click="onUrge">{{$t('一键催办')}}</van-button>
          </template>
        </template>
        <template v-if="showExamine">
          <van-button class="btn back" type="submit" @click="onAudit(0)">{{$t('驳回')}}</van-button>
          <van-button class="btn submit" native-type="button" @click.stop="onAudit(1)">{{$t('通过')}}</van-button>
        </template>
      </div>
    </van-form>
    <!-- 撤回申请弹窗 -->
    <van-dialog
      v-model="revokeDialog"
      class="revokeDialog"
      :title="''"
      show-cancel-button
      confirmButtonColor="#EED484"
      :confirm-button-text="$t('确认')"
      :before-close="beforeClose">
      <van-form ref="revokeDialogForm" label-width='65px' validate-trigger="onSubmit">
        <van-field
          v-model="revokeParams.refuseReason"
          :label="$t('')"
          style="margin-top: 16px;"
          :placeholder="revokeType === 1 ? $t('请输入撤回原因') : $t('请输入取消原因')"
          show-word-limit
          maxlength="200"
          rows="4"
          autosize
          type="textarea"
          :rules="[{ required: true }]" />
        <p v-if="revokeType === 1" class="tips">{{ $t('撤回后，可以修改申请信息重新提交审核，若客户无需退单，则可以撤回后取消退单申请') }}</p>
        <p v-if="revokeType === 0" class="tips">{{ $t('取消则视为客户不需要退单，维持原订单信息') }}</p>
      </van-form>
    </van-dialog>
  </div>
</template>
<script>
import OrderTitle from '../order/component/orderTitle'
import VehicleInfo from '../order/component/vehicleInfo'
import loading from '@/utils/loading'
import orderServices from '@/services/orderServices'
import { mapGetters } from 'vuex'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import clueServices from '@/services/clueServices'
import Trips from '@/modules/clue/component/trips/index.vue'
export default {
  name: 'RefundOrderManage',
  components: {
    OrderTitle,
    VehicleInfo,
    Trips
  },
  
  data() {
    return {
      orderId: '',
      orderDetail: {},
      queryMark: false,
      imgList: [], // 图片
      fileList: [], // 文件
      params: {
        applyReason: ''
      },
      revokeDialog: false, // 撤回/取消弹窗
      revokeParams: {
        refuseReason: ''
      },
      rejectParams: {
        refuseReason: '',
      },
      revokeType: 1, // 1 撤回 0 取消
      reEdit: false,
      tripDetails: {
        trips: []
      }, //  轨迹数据
    }
  },
  computed:{
    ...mapGetters(['dictHash','userInfo']),
    // 展示可编辑
    // 初次提交退单 || 重新提交审核
    showEdit() {
      return this.showRefund || (this.isSelf && this.judgeReject)
    },
    showFooter() {
      return [this.showRefund, this.isSelf, this.showExamine].some((item) => { return item })
    },
    // 申请退单相关的角色
    refundOrderRole() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos?.includes('1014002') || roleLogos?.includes('1014003')
    },
    // 是否展示退单按钮
    showRefund() {
      return this.orderDetail.applyRefund && this.refundOrderRole
    },
    // 判断是否已驳回 || 撤回
    judgeReject() {
      const { refund={} } = this.orderDetail
      const { applyStatus } = refund
      return ['3001002', '3001025', '3001026','3001027','3001028'].includes(applyStatus)
    },
    // 是否是自己的数据
    isSelf() {
      const { refund={} } = this.orderDetail
      const { applyUserId } = refund
      return this.userInfo.id === applyUserId
    },
    headerTips() {
      const arr = this.dictHash[3001]
      const { orderUpdateStatus, refund={} } = this.orderDetail
      const { refuseReason='', applyReason, applyStatus } = refund
      const statusName = arr.find(({ code }) => { return code === orderUpdateStatus })?.name
      // 有驳回原因 或者撤回原因需要展示
      const showReason = (applyStatus === '3001002' && applyReason) || (['3001025','3001026','3001027','3001028'].includes(applyStatus) && refuseReason)
      return showReason ? `${this.$t('退单申请：')}${statusName} | ${showReason}` : `${this.$t('退单申请：')}${statusName}`
    },
    // 是否展示审核相关按钮
    showExamine() {
      // 四个状态 对应 四个审核角色
      // 城市经理审批中-3001005 城市经理 1014030
      // 大区经理中-3001007 区域经理 1014021
      // SO审批中-3001008 SO-1014050
      // 财务审批中-3001028 财务-1014051
      const applyStatus = this.orderDetail?.refund?.applyStatus
      const { roleLogos = [] } = this.userInfo
      const matchArr = [
        applyStatus==='3001005'&& roleLogos.includes('1014030'),
        applyStatus==='3001007'&& roleLogos.includes('1014021'),
        applyStatus==='3001008'&& roleLogos.includes('1014050'),
        applyStatus==='3001012'&& roleLogos.includes('1014051'),
      ]
      return matchArr.some((item) => { return item })
    },
    fileTips() {
      const arr = this.dictHash[3006] || []
      const name = arr.find(({ code }) => { return code === '3006001' })?.name
      return `${this.$t('注：')}${name}`
    }
  },
  async mounted() {
    const { id } = this.$route.query
    this.orderId = id
    await this.getDetail()
    await this.getTrips()
  },
  methods: {
    // 获取订单详情
    async getDetail() {
      loading.showLoading()
      const res = await orderServices.getOrderDetail({ id: this.orderId })
      this.queryMark = true
      loading.hideLoading()
      this.orderDetail = res
      const { refund = {} } = res
      if (refund.id) {
        const { applyReason, applyImgUrl, applyUrl } = refund
        if (!this.showEdit) {
          this.params.applyReason = applyReason
        }
        this.imgList = applyImgUrl ? applyImgUrl.split(',').map((item) => {
          return {
            url: item,
            cosUrl: item,
            status: 'done',
            uuid: uuidv4(),
          }
        }) : []
        this.fileList = applyUrl ? applyUrl.split(',').map((item) => {
          return {
            url: item,
            cosUrl: item,
            status: 'done',
            uuid: uuidv4()
          }
        }) : []
      }
    },
    // 获取轨迹
    async getTrips() {
      const params = { 
        id: this.orderDetail.leadId,
        orderId: this.orderId,
        type: 'ORDER_CANCEL'
      }
      const res = await clueServices.getTrips(params)
      this.tripDetails = res
    },
    // 图片校验
    beforeRead(file, type) {
      const isLt10M = file.size / 1024 / 1024 > (type === 'img' ? 20 : 30)
      if (type === 'img' && (!file.type.includes('image') || isLt10M)) {
        this.$toast.fail(this.$t('请上传正确图片文件，单张最大20M'))
        return false
      }
      const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      const fileRules = ['doc','docx','xlsx','xlx','pdf','zip','rar']
      if (type === 'file' && (!fileRules.includes(str.toLocaleLowerCase()) || isLt10M )) {
        this.$toast.fail(this.$t('请上传正确文件附件，单个最大30M'))
        return false
      }
      return true
    },
    afterRead(file, type) {
      const uuid = uuidv4()
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file)
        .then(({ url, previewUrl }) => {
          if (file) {
            file.status = 'done'
            file.message = ''
            file.url = previewUrl
            file.cosUrl = url
            if (type === 'img') {
              this.imgList.push(file)
            } else {
              this.fileList.push(file)
            }
          }
        })
        .catch(() => {
          file.status = 'failed'
          file.message = this.$t('上传失败')
        })
    },
    downFile(data) {
      if (data.content) {
        console.log(11111)
        window.open(data.content)
      } else {
        console.log(22222)
        window.open(data.cosUrl)
      }
    },
    beforeDelete(file, type) {
      if (type === 'img') {
        this.imgList = this.imgList.filter((item) => item.uuid !== file.uuid)
      } else {
        this.fileList = this.fileList.filter((item) => item.uuid !== file.uuid)
      }
    },
    // 返回
    goBack() {
      this.$dialog
        .confirm({
          title: '',
          message: this.$t('确认取消申请？'),
          confirmButtonColor: '#B9921A',
        })
        .then(async () => {
          this.$router.go(-1)
        })
    },
    // 提交审核
    submitExamine() {
      this.$refs.form.validate().then(async () => {
        if (this.fileList.length === 0 && this.imgList.length === 0) {
          this.$toast('请上传申请材料')
          return false
        }
        this.$dialog.confirm({
          title: '',
          message: this.$t('确认提交退单申请？'),
          confirmButtonColor: '#B9921A',
          beforeClose: async (action, done)=>{
            if (action === 'confirm') {
              let fileList = this.fileList || []
              let imgList = this.imgList || []
              // 已经签名的图片 需要截取掉签名信息之后 再传给后端
              fileList = fileList.map(({ cosUrl }) => {
                return cosUrl?.indexOf('?q-sign-algorithm') == -1 ? cosUrl : cosUrl.slice(0, cosUrl.indexOf('?q-sign-algorithm'))
              })
              imgList = imgList.map(({ cosUrl }) => {
                return cosUrl?.indexOf('?q-sign-algorithm') == -1 ? cosUrl : cosUrl.slice(0, cosUrl.indexOf('?q-sign-algorithm'))
              })
              const applyUrl = fileList.join(',')
              const applyImgUrl = imgList.join(',')
              const submitParams = {
                applyUrl,
                applyImgUrl,
                applyReason: this.params.applyReason,
                orderId: this.orderId,
                id: this.orderDetail.refund?.id
              }
              try {
                await orderServices.applyRefundOrder(submitParams)
                done()
                this.$toast(this.$t('操作成功！'))
                setTimeout(() => {
                  if (window.history.length>1){
                    this.$router.go(-1)
                  } else {
                    window.location.reload()
                  }
                }, 1500)
              } catch ({ message }) {
                done()
              } 
            } else {
              done()
            }
          }
        })
      })
    },
    // 撤回 | 取消
    revokeApply(type) {
      this.revokeDialog = true
      this.revokeType = type
    },
    // 撤回确认
    beforeClose(action, done) {
      if (action === 'confirm') {
        this.$refs.revokeDialogForm.validate().then(async () => {
          const params = {
            orderId: this.$route.query.id,
            result: this.revokeType,
            refuseReason: this.revokeParams.refuseReason
          }
          if (this.btnLoading) return false
          this.btnLoading = true
          loading.showLoading()
          try {
            await orderServices.refundOrderCancel(params)
            done()
            this.btnLoading = false
            loading.hideLoading()
            this.$toast.success(this.$t('操作成功！'))
            // 撤回刷新当前页面 取消回到上一个页面
            if (this.revokeType === 1) {
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            } else {
              if (window.history.length>1){
                this.$router.go(-1)
              } else {
                window.location.reload()
              }
            }
          } catch {
            done()
            this.btnLoading = false
          }
        }, done.bind(null, false))
      } else {
        done()
      }
    },
    // 一键催办
    async onUrge() {
      orderServices.remindRefund({ orderId: this.$route.query.id })
      this.$toast(this.$t('催办成功！'))
    },
    // 审批
    async onAudit(result) {
      const tips = result === 1 ? this.$t('请核对申请材料是否完善和准确，确认审核通过？') : this.$t('驳回后，发起人可修改申请信息后重新提交审批，或取消退单申请，确认驳回？')
      const validateRes = await this.$refs.form.validate().then(() => {
        return true
      }).catch(() => {
        return false
      })
      if (!result && !validateRes) {
        // await this.$refs.form.validate()
        const refuseReasonBox = document.getElementById('refuseReasonBox')
        if (refuseReasonBox) refuseReasonBox.scrollIntoView({ behavior: 'smooth' })
        return false
      }
      this.$dialog.confirm({
        title: '',
        message: tips,
        confirmButtonColor: '#B9921A',
        beforeClose: async (action, done)=>{
          if (action === 'confirm') {
            const submitParams = {
              refuseReason: this.rejectParams.refuseReason,
              orderId: this.orderId,
              result
            }
            try {
              await orderServices.refundOrderAduit(submitParams)
              done()
              this.$toast(this.$t('操作成功！'))
              setTimeout(() => {
                if (window.history.length>1){
                  this.$router.go(-1)
                } else {
                  window.location.reload()
                }
              }, 1500)
            } catch ({ message }) {
              done()
            } 
          } else {
            done()
          }
        }
      })
    },
    // 跳转订单详情
    goOrderDetail() {
      this.$router.push({
        path: '/order-detail',
        query: {
          id: this.orderId,
        },
      })
    }
  }
}
</script>
<style lang="less" scoped>
.refundOrderManage{
  display: flex;
  flex-direction: column;
  color: #0d171a;
  font-size: 13px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .formBox{
    padding: 16px;
  }
  &.refundOrderManage-pab{
    padding-bottom: 96px;
  }
  .refundOrder-item{
    background: #ffffff;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 16px;
    &.top{
      padding-top: 0px;
      // background: #F4F8FE;
      .common-t{
        margin-bottom: 8px;
      }
      /deep/ .order-i{
        .orderTitle{
          border: none;
          padding-bottom: 0px;
        }
      }
    }
    &.bottom{
      padding: 0px;
      .common-t{
        padding: 0 12px;
      }
      .uploadBox{
        padding: 12px;
        display: flex;
        flex-direction: column;
        &.uploadBox_notEdit{
          /deep/ .van-uploader__upload{
            display: none
          }
        }
        .tips{
          font-size: 12px;
        }
      }
    }
    &.trip{
      margin-bottom: 0px;
    }
  }
  .previewTips{
    margin-bottom: 8px;
    color: #e4002c;
    font-size: 12px;
  }
  .revokeDialog{
    .van-form{
      .van-cell::after{
        display: none;
      }
      .tips{
        padding: 0 12px;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>