<template>
<div class="vehicleInfo">
  <div class="info">
    <div class="model">
      {{
        `${orderDetail.carOrderMaterial?.seriesName} ${orderDetail.carOrderMaterial?.modelName}`
      }}
    </div>
    <div class="amount">
      <span content="¥"></span>{{ `${formatRMB(orderDetail.totalAmount)}` }}
    </div>
  </div>
  <div class="item">
    <div class="common-label-t">{{$t('车身颜色：')}}</div>
    <div class="common-label-c special-info-box">
      <span>{{ orderDetail.carOrderMaterial?.colourName }}</span>
      <span v-if="orderDetail.coVehicleSource === 2" class="sepcial-car">{{ orderDetail.coVehicleSource === 2 ? '特殊车（非一车一价）' : '' }}</span>
    </div>
  </div>
  <div class="item">
    <div class="common-label-t">{{$t('内饰颜色：')}}</div>
    <div class="common-label-c">
      {{ orderDetail.carOrderMaterial?.interiorName }}
    </div>
  </div>
  <div class="item">
    <div class="common-label-t">{{$t('选装：')}}</div>
    <div class="common-label-c options">
      <div
        v-for="(optionsItem, index) in orderDetail.carOptionsList || []"
        :key="index"
        class="options-t"
      >
        {{ `${index + 1}、${optionsItem.optionsName || ""}` }}
      </div>
    </div>
  </div>
  <!-- 大客户不展示 预计交付时间 -->
  <div v-if="orderDetail.orderType!==1" class="item">
    <div class="common-label-t no">{{$t('预计交付时间：')}}</div>
    <div class="common-label-c">
      {{ `${orderDetail.predictDeliveryDate}${$t('week')}` }}
    </div>
  </div>
  <div v-if="showMore" class="row">
    <div class="item">
      <div class="common-label-t">{{$t('订单状态：')}}</div>
      <div class="common-label-c">{{ orderDetail.orderStatus | codeFormat }}</div>
    </div>
    <div class="item">
      <div class="common-label-t">{{$t('金融状态：')}}</div>
      <div class="common-label-c">{{ orderDetail.financeStatus | codeFormat }}</div>
    </div>
  </div>
  <div v-if="orderDetail.salesDealerName" class="item">
    <div class="common-label-t">{{$t('销售门店：')}}</div>
    <div class="common-label-c">{{ orderDetail.salesDealerName }}</div>
  </div>
  <div v-if="orderDetail.deliveryDealerName" class="item">
    <div class="common-label-t">{{$t('交付门店：')}}</div>
    <div class="common-label-c">{{ orderDetail.deliveryDealerName }}</div>
  </div>
  <div class="row">
    <div class="item">
      <div class="common-label-t">{{$t('销售顾问')}}：</div>
      <div class="common-label-c">{{ orderDetail.salesUserName || '--' }}</div>
    </div>
    <div class="item">
      <div class="common-label-t">{{$t('交付专员')}}：</div>
      <div class="common-label-c">{{ orderDetail.deliveryUserName || '--' }}</div>
    </div>
  </div>
  <div class="item">
    <div class="common-label-t">{{$t('电子合同编号：')}}</div>
    <div class="common-label-c">{{ orderDetail.contractNo || '-' }}</div>
  </div>
  <template v-if="fromClue">
    <div class="item">
      <div class="common-label-t">{{$t('订单状态：')}}</div>
      <div :class="['common-label-c', `STATUS_${orderDetail.orderStatus}`]">
        {{ orderDetail.orderStatus | codeFormat }}
      </div>
    </div>
    <div class="footer" @click="goOrderDetail(orderDetail.id)">
      {{$t('查看订单详情')}}
    </div>
  </template>
</div>
</template>
<script>
export default {
  name: 'vehicleInfo',
  props: {
    orderDetail: {
      type: Object,
      default: () => ({}),
    },
    fromClue: {
      type: Boolean,
      default: () => false,
    },
    showMore: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {}
  },
  methods: {
    goOrderDetail(id) {
      this.$router.push({
        path: '/order-detail',
        query: {
          id,
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.vehicleInfo {
  display: flex;
  flex-direction: column;

  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .model {
      font-size: 16px;
    }

    .amount {
      font-size: 14px;
      color: #e4002c;
    }
  }
  .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item{
      flex: 1;
      overflow: hidden;
    }
  }
  .item {
    margin-bottom: 8px;
    display: flex;
  }
  .common-label-t{
    flex-wrap: wrap;
    max-width: 94px;
  }
  .footer {
    font-size: 16px;
    color: @yellow-text;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .special-info-box {
    display: flex;
    justify-content: space-between;
  }
  .sepcial-car {
    padding: 2px 8px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    line-height: 16px;
    font-size: 12px;
    border-color: rgba(0, 64, 197, 0.1);
    color: #ee0a24;
    background: #ee0a2421;
  }
}
</style>
